import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import KontakHero from '../components/Kontak/KontakHero';



const Kontak = () => (
  <>
    <Navbar />
    <KontakHero />
    <Footer />
  </>
)

export default Kontak