import React from 'react'

const KontakHero = () => {
  return (
    <>
     

      <div class=' bg-success pt-5 pb-5 '>
        <h1 class='display-4 pt-5 pb-5 mb-5 text-white m-5 font-header'> ANTRE AN KONTAK AVÈK NOU  </h1>
      </div>


      <div class="container font-text">
        <div class="row mx-0 justify-content-center">
          <div class="col-md-7 col-lg-5 px-lg-2 col-xl-4 px-xl-0 px-xxl-3">
            <form
              method="POST"
              class="w-100 rounded-1 p-4 border bg-white"
              action="https://public.herotofu.com/v1/7bfdbdc0-8863-11ed-b38f-a1ed22f366b1">
              <label class="d-block mb-4">
                <span class="form-label d-block">Siyati:</span>
                <input
                  name="siyati"
                  type="text"
                  required
                  class="form-control"
                  placeholder="Antre siyati ou"
                />
              </label>
              <label class="d-block mb-4">
                <span class="form-label d-block">Non:</span>
                <input
                  name="non"
                  type="text"
                  required
                  class="form-control"
                  placeholder="Antre non ou"
                />
              </label>

              <label class="d-block mb-4">
                <span class="form-label d-block">Imel:</span>
                <input
                  name="Imel"
                  type="email"
                  class="form-control"
                  placeholder="imel@egzamp.com"
                  required
                />
              </label>

              <label class="d-block mb-4">
                <span class="form-label d-block">Telefòn:</span>
                <input
                  name="telefòn"
                  type="tel"
                  required
                  class="form-control"
                  placeholder="+509 0000-0000"
                />
              </label>

              <label class="d-block mb-4">
                <span class="form-label d-block">Mesaj:</span>
                <textarea
                  name="mesaj"
                  class="form-control"
                  rows="4"
                  placeholder="Ekri sa ou anvi pale ak nou an."
                  required
                ></textarea>
              </label>

              <div class="mb-3">
                <button type="submit" class="btn btn-success px-3 rounded-3">
                  Soumèt          </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </>
  )
}

export default KontakHero