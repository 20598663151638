import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from "react-router-dom";



const FomileApp = () => {

  function BtnMessage() {
    alert('Pa gen LOAN disponib pou mwa sa, Eseye lòt mwa Mèsi !');
  }

  return (
    <>
      <div class='pb-5'>
        <Navbar />
      </div>

     
      <div class=' bg-success pt-5 pb-5 font-text'>
        <h1 class='display-4 pt-5 pb-5 mb-5 text-white m-5 font-header'> Siw gen yon prè ou te fè deja, antre nimewo idandifyan'w lan pou'w aplike pou yon nouvo prè !</h1>
        <form
          method="POST"
          class="w-100 rounded-1 p-4 border bg-white"
          action=" https://public.herotofu.com/v1/e00e04b0-8c54-11ed-a003-6f0b76086b1c"
        >
          <label class="d-block mb-4">
                <span class="form-label d-block">Antre nimewo idantifyan'w lan:</span>
                <input
                  name="Idantifyan"
                  type="text"
                  required
                  class="form-control"
                  placeholder="Idantifyan...."

                />
              </label>

              <div class="mb-3">
                <button type="submit" class="btn btn-success px-3 rounded-3" >
                  Soumèt
                </button>
              </div>
        </form>
      </div>




      <Footer />

    </>
  )
}

export default FomileApp