import React from 'react'

const Hero = () => {
  return (
    <div class=' bg-success pt-5 pb-5 font-header'>
      <h1 class='display-4 pt-5 pb-5 mb-5 text-white m-5'> FÈ YON PATENARYA AVÈK NOU </h1>
    </div>
  )
}

export default Hero