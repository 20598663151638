import React from 'react'

const error = () => {
  return (
    <div class="container error-container">
	<div class="row  d-flex align-items-center justify-content-center">
		<div class="col-md-12 text-center">
			<h1 class="big-text">Oops!</h1>
			<h2 class="small-text">404 - PAGE NOT FOUND</h2>

		</div>
		<div class="col-md-6  text-center">
			<p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>


			<form class="example" action="action_page.php">
				

			</form>
			 <h2 class="small-text">Ou Byen</h2>
			
			<a href="/mesi" class="button button-dark-blue iq-mt-15 text-center">Ale nan paj Akey</a>

		</div>

	</div>
    </div>
  )
}

export default error