import React from 'react'
import { Link } from "react-router-dom";
import Navbar from './components/Navbar';
import Footer from './components/Footer';

const mesi = () => {
    return (
        <>   
        <div class='pb-5 mb-5'>
            <Navbar />
            </div>

            <div class="jumbotron text-center mt-5">
                <h1 class="display-3 uppercase mt-5 p-5">Mesi, Ou ranpli fom nan avek siksè !</h1>
                <p class="lead"><strong>Sil vou plè, Verifye Imel ou nan yon ti tan anko pou plis enfomasyon.</strong></p>

                <p>
                    Ou panse gen yon pwoblèm ki pase pandan ou tap soumèt fom nan? <Link to='/Kontak'>KONTAKTE NOU</Link>
                </p>
                <p class="lead m-5">
                    <Link to='/'>KONTINYE NAN PAJ AKEY NOU AN.</Link>
                </p>
            </div>

           
                <Footer />
            

        </>

    )
}

export default mesi