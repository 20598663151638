import React from 'react'
import { Link } from "react-router-dom";
import FomileApp from './FomileReact/FomileApp'
import { FaWhatsapp } from 'react-icons/fa';


const SevisHero = () => {

    const navigateFom = () => {
        // 👇️ navigate to /
       // <FomileApp />
       alert('Pa gen LOAN disponib pou mwa sa, Eseye lòt mwa Mèsi !');

    };
    return (

        <>
            <div class=' bg-success pt-5 pb-5 '>
                <h1 class='display-4 pt-5 pb-5 mb-5 text-white m-5 font-header'> SÈVIS NOU OFRI YO </h1>
            </div>

            <div class='text-center text-black p-5'>
                <h3 class='display-6 underline p-4 font-header'> <u>EMERGENCY LOAN </u></h3>
                <p class='m-4 fs-4 font-text'>Se yon ògan prive kote yon senp sitwayen chwazi prete lajan ak enterè.
                    Nou gen plizyè plan ki kapab ede'w ak bezwen ou genyen an.
                </p>
                 <Link to='/fomileApp'> <a href='#'>  <button type="button"  class="btn btn-dark btn-lg m-4 font-text">APLIKE</button> </a></Link>
            </div>

            <div class=' text-center text-black p-5 bg-success text-white '>
                <h3 class='display-6 underline p-4 font-header'> <u>GARIZIM BUREAU DE CHANGE </u></h3>
                <p class='m-4 fs-4 font-text'>Nou chanje e nou vann lajan ameriken nan bon to. Si'w bezwen fè echanj ki asire, rapid nou disponib pou ou.
                </p>
                <div class='justify-content-center d-flex flex-row bd-highlight '>
                    <button type="button" class="btn btn-dark btn-lg m-4 text-rightp-2 bd-highlight font-text">  garizimht22@gmail.com</button>

                    <button type="button" class="btn btn-dark btn-lg m-4 p-2 bd-highlight d-flex font-text">  <FaWhatsapp
                        size={25} style={{ color: '#ffffff' }} /> +509 3920-2682 </button>
                </div>
            </div>

            <div class=' text-center text-black p-5'>
                <h3 class='display-6 underline p-4 font-header'> <u>ELECTRICITE </u></h3>
                <p class=' fs-5 font-text'> 1- Enstalasyon aparan / ankastre </p>
                <p class='fs-5 font-text'>2- Enstalasyon invètè manyèl / otomatik </p>
                <p class='fs-5 font-text'>3- Montaj plizyè sous enèji</p>
                <p class=' fs-5 font-text'>4- Enstalasyon pano solè</p>
                <p class=' fs-5 font-text'>5- Enstalasyon kamera</p>

                <div class='justify-content-center d-flex flex-row bd-highlight '>
                    <button type="button" class="btn btn-dark btn-lg m-4 text-rightp-2 bd-highlight font-text">  garizimht22@gmail.com</button>

                    <button type="button" class="btn btn-dark btn-lg m-4 p-2 bd-highlight d-flex font-text">  <FaWhatsapp
                        size={25} style={{ color: '#ffffff' }} /> +509 3920-2682 </button>
                </div>
            </div>

            <div class=' text-center text-black p-5 bg-success text-white w-full'>
                <h3 class='display-6 underline p-4 font-header '> <u>INFORMATIQUE BUREATIQUE </u></h3>
                <p class='m-4 fs-4 font-text'> Nou gen  program infomatik ki disponib pou tout moun, debitan oubyen entèmedyè. Ou pa bezwen deplase pou’w aprann, n’ap vinn jwenn ou.
                </p>
                <div class='justify-content-center d-flex flex-row bd-highlight '>
                <button type="button" class="btn btn-dark btn-lg m-2 text-rightp-2 bd-highlight font-text">  garizimht22@gmail.com</button> 

<button type="button" class="btn btn-dark btn-lg p-2 m-2 bd-highlight d-flex font-text ">  <FaWhatsapp
size={25} style={{ color: '#ffffff' }} /> +509 3920-2682 </button> 
                </div>
            </div>

            <div class=' text-center text-black p-5  '>
                <h3 class='display-6 underline p-4 font-header'> <u>MATERIAUX DE CONSTRUCTION </u></h3>
                <p class='m-4 fs-4 font-text'>Si'w bezwen achte sab, gravye, siman, fè, materyèl elektrik, ak plonbri elatriye.
                    N'ap fe'w jwenn yo ak bon pri e bon kalite.
                </p>
                <div class='justify-content-center d-flex flex-row bd-highlight '>
                <button type="button" class="btn btn-dark btn-lg m-4 text-rightp-2 bd-highlight font-text">  garizimht22@gmail.com</button> 

<button type="button" class="btn btn-dark btn-lg m-4 p-2 bd-highlight d-flex font-text">  <FaWhatsapp
size={25} style={{ color: '#ffffff' }} /> +509 3920-2682 </button> 
                </div>
            </div>
        </>
    )
}

export default SevisHero