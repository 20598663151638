import React from 'react'

import Navbar from '../Navbar';
import Footer from '../Footer';

const Echange = () => {
    return (
        <>
            <Navbar />
            <div class=' bg-success pt-5 pb-5 '>
                <h1 class='display-4 pt-5 text-white m-5 underline font-header'> Depatman chanj GARIZIM </h1>
                <p class=' m-5 fs-6 pb-5 text-white mb-5 mt-0 pt-0 '> GARIZIM Bureau d'échange se yon branch nan GARIZIM S.A. ki la pou chanje ak vann dola ameriken</p>
            </div>
            <div class="row mx-0 justify-content-center">
                <div class="col-md-7 col-lg-5 px-lg-2 col-xl-4 px-xl-0 px-xxl-3 justify-content-center">

                    <form action="https://public.herotofu.com/v1/2300d460-6304-11ee-a450-5f0c9649ec83" method="post" accept-charset="UTF-8">

                        <label class="d-block mb-4 mt-5">
                            <span class="form-label d-block"
                            >Kisa w'ap fè ?</span>
                            <select name="chwa" required='yes' class="form-select">
                                <option value="" disabled selected hidden> </option>
                                <option>Chanje</option>
                                <option>Achte</option>
                            </select>
                        </label>


                        <label class="d-block mb-4 ">
                            <span class="form-label d-block">Ki kantite ?</span>
                            <input
                                name="kantite"
                                type="text"
                                required
                                class="form-control"
                                placeholder=" ant 1 a 2,000 $"

                            />
                        </label>

                        <label class="d-block mb-4">
                            <span class="form-label d-block">Mete yon nimewo Telefòn pou'n fè swivi ak ou </span>
                            <input
                                name="telefòn"
                                type="text"
                                required
                                class="form-control"
                                placeholder="+509 0000-0000"

                            />
                            <small id="" class="form-text text-muted"> * Nou pap janm pataje Telefòn  ou ak okenn moun. </small>
                        </label>

                        <label class="d-block mb-4">
                            <span class="form-label d-block">Antre non moun ki Refere'w sit la</span>
                            <input
                                name="referans"
                                type="text"
                                required
                                class="form-control"


                            />
                        </label>
                        <p class=' fs-6s mt-0 pt-0 italic'> To a varye daprè kantite w'ap achte oubyen chanje</p>


                        <button type="submit" class="btn btn-success px-3 rounded-3  justify-content-center mb-5">
                            Soumèt
                        </button>

                    </form>


                </div>
            </div>


            <Footer />
        </>
    )
}

export default Echange