import React from 'react'

const MisyonVisyon = () => {
  return (
    <div class='row bg-white w-full'>
      <div class='col-md-6 bg-dark text-white text-center p-5 '>
        <h1 class='display-6 font-header'>MISYON NOU</h1>
        <p class='fs-4 m-5 font-text'>Nou egziste nan lide pou nou pote pi bon sèvis nan kominote komèsyal la. Nou envesti
          nan projè ki rantab ak anpil disiplin.</p>
      </div>
      <div class='col-md-6 text-center  text-black p-5 '>
        <h1 class='display-6 font-header'>VIZYON NOU</h1>
        <p class='fs-4 m-5 font-text'>Nou vle elaji kad biznis dijital la, pou nou ede antrepriz lokal yo rive jwenn mache komèsyal la pi rapid.</p>
      </div>
    </div>
  )
}

export default MisyonVisyon