import React from 'react'
import { Link } from "react-router-dom";

const PatenaryaList = () => {
    return (
        <>
            <div class='text-center text-black font-header '>
                <h3 class='display-6 underline p-4'> <u>LIS PATENARYA NOU OFRI YO </u></h3>
            </div>
           

            <div class='justify-content-center d-flex flex-row bd-highlight text-success font-text'>
            <ul class='m-4 mb-5'>
                    <li><p class='fs-4 m-1 p-2'> Teknoloji</p> </li>
                    <li><p class='fs-4 m-1 p-2'>Plonbri</p> </li>
                    <li><p class='fs-4 m-1 p-2'>Konsepsyon / konstriksyon ak reparasyon kay</p> </li>
                </ul>

                <ul class='m-4 mb-5'>
                    <li><p class='fs-4 m-1 p-2'>Elektrik</p> </li>
                    <li><p class='fs-4 m-1 p-2'>Sèvis Tretè</p> </li>
                    
                    
                </ul>
                </div>

                <div class='justify-content-center text-center font-text'>
                <p class='fs-4 text-success m-1 pb-4'>Nou envesti nan projè ki rantab ak anpil disiplin. Nou gen anpil teknisyen konpetan k’ap bay sèvis nan domèn sa yo.</p>
            <Link to='/Kontak'><a href='/Kontak'> <button type="button" class="btn btn-dark btn-lg text-uppercase mb-5 font-text">Kontakte Nou</button></a></Link>

                </div>

        </>
    )
}

export default PatenaryaList