import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import SevisHero from '../components/SevisHero';


const Sevis = () => {
  return (
    <>
    <Navbar />
    <SevisHero />
    <Footer />
    </>
  )
}

export default Sevis