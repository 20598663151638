import React from 'react'
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from "react-router-dom";


const Politik = () => {
  return (
    <>
    <Navbar />
    <div class=' bg-success pt-5 pb-5 '>
    <h1 class='display-5 pt-5 pb-5 mb-5 text-white m-5 uppercase font-header'> Politik GARIZIM s.a. lè wap itilize sit entenèt nou an.</h1>
</div>

<div class='text-center font-text'>
  <p class=' m-4 fs-5 '>  GARIZIM S.A. vle fè tout moun ki gen aksè ak sit entenèt li a konnen: nou pa kolekte enfòmasyon pèsonèl itilizatè nou yo nan navigatè a. </p>
  <p class=' m-4 fs-5 '> Tout lyen ki sou platfòm nou an ap voye'w nan sa ki gen rapò ak nou. Gen sèvis nou ofri tankou <Link to='/Sevis'> Emergency Loan</Link> ki mande enfomasyon pèsonèl kliyan pou swivi ki nesesè. </p>
  <p class=' m-4 fs-5 '> Nou pa pataje enfòmasyon yo ak pyès lot moun, òganizasyon, sit entenèt oswa antrepriz. 
  Nou travay nan diskresyon ak respè pou tout kliyan nou yo.</p>
</div>
<Footer />
</>
  )
}

export default Politik