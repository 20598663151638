import React from 'react'
import { Link } from "react-router-dom";

const KonenPlisAksyon = () => {
  return (
    <div class='row bg-success  text-center text-white p-5 w-full'> 
            <h1 class='display-4 p-4 font-header'> Ou enterese ak youn nan sèvis nou ofri yo? </h1>
            <p class='fs-4 m-4 font-text'>Pran kontak avèk nou, ou byen ranpli fòmilè nou ki disponib sou sit la.</p>
          <Link to='/Sevis'> <a href='/Sevis'>  <button type="button" class="btn btn-dark btn-lg text-white m-4 uppercase font-text">Sèvis nou yo</button> </a> </Link>

    </div>
  )
}

export default KonenPlisAksyon