import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Footer from '../components/Footer';



const Akey = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <Footer />
    </div>

  )
}

export default Akey