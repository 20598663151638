import React from 'react'
import { FaPhone } from "react-icons/fa";
import { FaMailBulk } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

const Footer = () => {
  return (

    <div class=" row text-center bg-dark text-white justify-content-center w-full font-text fs-5">

      <div class=" col-md-4 ">
       
        <section class="mt-5">
          <a
            class="btn btn-link btn-floating btn-lg text-dark m-1"
            href=""
            role="button"
            data-mdb-ripple-color="dark"
          ><FaMailBulk size={25} style={{ color: '#ffffff' }} /> </a>
          <p className='text-white'>garizimht22@gmail.com</p>
          <a
            class="btn btn-link btn-floating btn-lg text-dark m-1"
            href=""
            role="button"
            data-mdb-ripple-color="dark"> <FaWhatsapp
              size={25} style={{ color: '#ffffff' }} /> </a>

          <p className='text-white'>+509 3920-2682</p>


          <a
            class="btn btn-link btn-floating btn-lg text-dark m-1"
            href=""
            role="button"
            data-mdb-ripple-color="dark"
          > <FaPhone
              size={25} style={{ color: '#ffffff' }} /></a>

          <p className='text-white'>+509 3920-2682</p>
        </section>
      </div>


      <div class="col-md-4 ">
        <ul class="navbar-nav ms-auto mt-5">
          <li class="nav-item ">
            <Link to='/'> <a class="nav-link mx-2 text-white" aria-current="page" href="/">AKÈY</a></Link>
          </li>
          <li class="nav-item">
            <Link to='/Konenplis'> <a class="nav-link mx-2 text-white" href="/KonenPlis">KONEN PLIS</a></Link>
          </li>
          <li class="nav-item">
            <Link to='/Sevis'> <a class="nav-link mx-2 text-white" href="/Sevis">SÈVIS NOU OFRI YO</a></Link>
          </li>
          <li class="nav-item dropdown">
            <Link to='/Patenarya'> <a class="nav-link mx-2 text-white" href="/Patenarya">PATENARYA</a> </Link>
          </li>
          <li class="nav-item dropdown">
            <Link to='/Kontak'> <a class="nav-link mx-2 text-white" href="/TemAkKondisyon">KONTAK</a> </Link>

          </li>
        </ul>
      </div>


      <div class="col-md-4 box text-center mt-5 ">
        <ul class="navbar-nav ms-auto ">
          <li class="nav-item">
            <Link to='/Politik'> <a class="nav-link mx-2 active text-white uppercase" aria-current="page" href="/Politik">Politik ak Konfidansyalite</a> </Link>
          </li>
          <li class="nav-item">
            <Link to='/TemAkKondisyon'><a class="nav-link mx-2 text-white uppercase" href="/TemAkKondisyon">Tèm ak Kondisyon</a></Link>
          </li>
        </ul>
      </div>



      <div class='p-3'>
        <span class="copyright quick-links text-start ">Copyright &copy; 2022 - {new Date().getFullYear()} GARIZIM HAITI S.A. | KREYASYON <a class='text-white' href='https://cterogene.netlify.app' target="_blank">CTEROGENE</a>
        </span>
      </div>

    </div>
  )
}

export default Footer