import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import Akey from './container/Akey';
import KonenPlis from './container/KonenPlis';
import Sevis from './container/Sevis';
import Patenarya from './container/Patenarya';
import Kontak from './container/Kontak';
import FomileApp from './components/FomileReact/FomileApp';
import TemAkKondisyon from './components/TemAkKondisyon';
import Mesi from './Mesi';
import Error from './Error';
import Politik from './components/Politik';
import Echange from './components/Echange/Echange';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'




function App() {
  
  return (
    <>
      <Router>
        <Routes>
        <Route exact path="/" element={<Akey/>}/>
        <Route exact path="/konenPlis" element={<KonenPlis/>}/>
        <Route exact path="/Sevis" element={<Sevis/>}/>
        <Route exact path="/Patenarya" element={<Patenarya/>}/>
        <Route exact path="/Kontak" element={<Kontak/>}/>
        <Route exact path="/FomileApp" element={<FomileApp/>}/>
        <Route exact path="/TemAkKondisyon" element={<TemAkKondisyon/>}/>
        <Route exact path="/Politik" element={<Politik/>}/>
        <Route exact path="/Mesi" element={<Mesi/>}/>
        <Route exact path="/Echange" element={<Echange />} />
        <Route exact path="/*" element={<Error/>}/>


        </Routes>
      </Router>
    </>


  );
}

export default App;
