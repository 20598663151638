import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

const TemAkKondisyon = () => {
  return (
    <>
    <Navbar />
    <div class=' bg-success pt-5 pb-5 '>
    <h1 class='display-4 pt-5 pb-5 mb-5 text-white m-5 font-header'> TÈM AK KONDISYON NOU YO</h1>
</div>

<div class='justify-content-center text-center font-text'>
  <p class=' m-4 fs-5 justify-content-center'> 1 - Yon kliyan ki aplike pou yon sèvis sou sit wèb la, Nou garanti ke swivi ap fèt pou li jwenn yon repons. 
  Menm si se yon  repons ki pa aprouve, nou ap reponn ou. </p>
  <p class=' m-4 fs-5 justify-content-center'> 2 - Enfòmasyon pèsonèl yo dwe korèk ak pyès ou yo.</p>
  <p class=' m-4 fs-5 justify-content-center'> 3 - Yon moun ki aplike sou sit la ap resevwa yon kòd idantifyan pou li menn sèlman. 
  N'ap verifye enfòmasyon pèsonèl ou yo pou yon nouvo prè. Ou pa dwe pataje kod sa ak pèson. </p>
  <p class=' m-4 fs-5 justify-content-center'> 4 - Yon moun ki vle kolabore ak nou dwe aksepte ak tout kondisyon entèn GARIZIM S.A.</p>

</div>
<Footer />
</>
)
}

export default TemAkKondisyon