import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import KonenPlisHero from '../components/KonenPlisHero';
import MisyonVisyon from '../components/MisyonVisyon';
import KonenPlisAksyon from '../components/KonenPlisAksyon';


const KonenPlis = () => {
  return (
    <>
    <Navbar />
    <KonenPlisHero />
    <MisyonVisyon />
    <KonenPlisAksyon />
    <Footer />
    </>

  )
}

export default KonenPlis