import * as React from 'react';
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import electricite from '../assets/images/electricite.jpg';
import emergency from '../assets/images/emergency.jpg';
import informatique from '../assets/images/informatique.jpg';
import materiaux from '../assets/images/materiaux.jpg';
import echange from '../assets/images/echange.jpg';
import patenarya from '../assets/images/patenarya.png';
import web from '../assets/images/web.png';
import garizim from '../assets/images/Garizim-Service.png'
import livrezon from '../assets/images/livrezon.png';
import Groupe from '../assets/images/Groupe-Division.png';
import envesti from '../assets/images/envesti.png';
import wilda from '../assets/images/wilda.png'
import { render } from 'react-dom';
import ReactWhatsappButton from "react-whatsapp-button";
import DateTimeComponent from './DateTimeComponent';

import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";



const Hero = () => {
  
  return (
    <>

      <ReactWhatsappButton
        countryCode="509"
        phoneNumber="39202682"
        animated
        message="Bonswa Garizim !"
      />
      <div class="grid grid-cols-6 gap-0">

        <div class="col-span-1 bg-green-300 fs-8 text-center  text-sm mt-5 pt-5 ">
        <h5 class='fs-4 font-text text-center underline'>Date/Heure</h5>
          <DateTimeComponent/>

          <h5 class='fs-4 font-text text-center underline'>Evènement National</h5>
          <p class=' fs-7 text-center m-1 text-sm '> 31 Mars: <i>Fête des Pâques </i></p>
          <p class=' fs-7 text-center m-1 text-sm '> 18 Mai: <i>Fête du Drapeau</i></p>
          <p class=' fs-7 text-center m-1 text-sm '> 26 Mai: <i> Fête des Mères</i></p>
<br/>
          <h5 class='fs-4 font-text text-center underline'>Promotions</h5>
        </div>
        <div class="col-span-4 bg-white-300 ">

          <MDBCarousel showControls showIndicators>

            <MDBCarouselItem
              className='w:auto d-block mt-5 pt-5'
              itemId={2}
              src={wilda}
              alt='...'
            >

            </MDBCarouselItem>

            <MDBCarouselItem
              className='w:full height:auto img-fluid mt-5 pt-5'
              itemId={3}
              src={envesti}
              alt='...'
            >
            </MDBCarouselItem>
            <MDBCarouselItem
              className='w:auto d-block h-auto mt-5 pt-5'
              itemId={2}
              src={informatique}
              alt='...'
            >

            </MDBCarouselItem>
            <MDBCarouselItem
              className='w:auto d-block mt-5 pt-5'
              itemId={3}
              src={materiaux}
              alt='...'
            >
            </MDBCarouselItem>

            <MDBCarouselItem
              className='w:auto d-block mt-5 pt-5'
              itemId={3}
              src={livrezon}
              alt='...'
            >
            </MDBCarouselItem>

            <MDBCarouselItem
              className='w:auto d-block mt-5 pt-5'
              itemId={3}
              src={Groupe}
              alt='...'
            >
            </MDBCarouselItem>




          </MDBCarousel>

        </div>
        <div class="col-span-1 bg-green-300 gap-0 mt-5 pt-5 text-center sm:text-sm">
          <h5 class='fs-4 font-text text-center underline'>Groupe GARIZIM S.A.</h5>
          <p class=' fs-8 text-center m-1 text-sm '> GARIZIM Services </p>
          <p class='fs-8 text-center m-1 text-sm'> GARIZIM Matériaux de construction </p>
          <p class='fs-8 text-center m-1 text-sm'> GARIZIM Bureau d'échange </p>
          <br />
          <h5 class='fs-4 font-text text-center underline'>Division GARIZIM S.A.</h5>
          <p class=' fs-8 text-center m-1 text-sm'> EMERGENCY Loan </p>
          <p class='fs-8 text-center m-1 text-sm'> WILDA Dépôt Divers </p>
          <p class='fs-8 text-center text-sm'>OBSTA Construction </p>

        </div>
      </div>

      <div className='text-center p-2 m-5'>
        <h1 className='display-3 font-header'>GARIZIM S.A.</h1>
        <p class='fs-4 m-5 font-text'>GARIZIM se yon antrepriz prive ki pran ekzitans li nan lane 2022. Nou se yon antrepriz finansman, ankadreman ak sèvis.</p>
        <Link to='/KonenPlis'>  <button type="button" class="btn btn-warning  btn-lg uppercase font-text">Konen plis</button></Link>
      </div>

      <div class="row w-full">
        <h1 class='display-3 text-center font-header'> DEPATMAN AK SÈVIS NOU OFRI YO </h1>
        <div class="col-sm-6">

          <div class="card m-5 " >
            <Link to='Sevis'><img src={emergency} width="30%" height="auto" class="card-img-top" alt="..." /></Link>
          </div>

        </div>
        <div class="col-sm-6">
          <div class="card m-5" >
            <Link to='/Echange'> <img src={echange} width="30%" height="auto" class="card-img-top" alt="..." /></Link>
          </div>

        </div>
      </div>


      <div class="row w-full">
        <div class="col-sm-6">
          <div class="card m-5" >
            <Link to='Sevis'><img src={electricite} width="30%" height="auto" class="card-img-top" alt="..." /></Link>
          </div>

        </div>
        <div class="col-sm-6 rounded-full">
          <div class="card m-5 rounded-full" >
            <Link to='Sevis'> <img src={garizim} class="card-img-top rounded-full" alt="..."  /></Link>

          </div>

        </div>
      </div>



      <div class="row w-full">
        <div class="col-sm-6">
          <div class="card m-5" >
            <Link to='Sevis'><img src={informatique} width="30%" height="auto" class="card-img-top" alt="..." /></Link>
          </div>

        </div>
        <div class="col-sm-6">
          <div class="card m-5" >
            <Link to='Sevis'> <img src={materiaux} width="30%" height="auto" class="card-img-top" alt="..." /></Link>

          </div>

        </div>
      </div>




      <div className='text-center p-2 m-5 bg-warnig '>
        <h1 class='display-3 text-center font-header'>KOLABORASYON</h1>
        <p class='fs-4 m-5 font-text'>Ou gen yon biznis, ou bezwen finansman, konsèy, fè pwomosyon, elatriye ...</p>
        <Link to='/Kontak'> <button type="button" class="btn btn-warning btn-lg uppercase font-text">Kontakte Nou</button></Link>

      </div>

    </>
  
  )
}

export default Hero