import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Hero from '../components/Patenarya/Hero';
import PatenaryaList from '../components/Patenarya/PatenaryaList';


const Patenarya = () => {
  return (
    <>
    <Navbar />
    <Hero />
    <PatenaryaList />
    <Footer />
    </>
  )
}

export default Patenarya