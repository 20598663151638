import React from 'react'

const KonenPlisHero = () => {
    return (
        <div class='bg-success pl-4 pt-4'>
            <h1 class='display-4 pt-5 pb-5 mb-5 text-white m-5 font-header'> KONEN PLIS SOU NOU </h1>

        </div>
    )
}

export default KonenPlisHero 