import React from 'react'
import './index.css';
import { Routes, Route } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import { Link } from "react-router-dom";
import { FaBars, FaTimes, FaSun, FaMoon } from "react-icons/fa";
import { useState } from "react";
const Navbar = () => {

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);



    return (


        <div className="header header-bg bg-orange-300 text-black font-header">
            <Link to="/#home">
                <img src={logo} alt='logo' width='200px' height='100px' />

            </Link>

            <ul className={click ? "nav-menu active dark:text-gray-200  " :
                "nav-menu"}>
                <li class="nav-item text-white">
                <Link to='/'><a class="nav-link  text-black bg-green-300 rounded p-2 " href="/"><strong>AKÈY</strong></a></Link>
                </li>
                <li class="nav-item">
                    <Link to='/Konenplis'><a class="nav-link mx-2 text-black bg-green-300 rounded p-2" href="/KonenPlis"><strong>KONEN PLIS</strong></a></Link>
                </li>
                <li class="nav-item">
                <Link to='/Sevis'> <a class="nav-link mx-2 text-black bg-green-300 rounded p-2" href="/Sevis"><strong>SÈVIS NOU OFRI YO</strong></a></Link>
                </li>
                <li class="nav-item dropdown">
                <Link to='/Patenarya'> <a class="nav-link mx-2 text-black bg-green-300 rounded p-2" href="/Patenarya"><strong>PATENARYA</strong></a></Link>
                </li>
                <li class="nav-item dropdown">
                <Link to='/Kontak'> <a class="nav-link mx-2 text-black bg-green-300 rounded text-bold p-2" href="/Kontak"><strong>KONTAK</strong></a></Link>

                </li>
            </ul>

        </div>

    )
}

export default Navbar